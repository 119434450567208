<template>
  <div class="app-s-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="app-s-title"
          v-lazy="require('../../../assets/images/title1.png')"
        />
        <div class="app-s-d1">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div
                class="p-item"
                align="center"
              >
                <img
                  class="m1"
                  v-lazy="item.icon"
                />
                <span
                  class="s1"
                  v-html="item.name"
                ></span>
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ApplicationScenario-sr",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon19.png"),
          name: "支持单店或连锁等</br>多种经营模式",
        },
        {
          icon: require("../../../assets/images/app-icon20.png"),
          name: "大数据分析后台</br>帮助实现科学决策",
        },
        {
          icon: require("../../../assets/images/app-icon21.png"),
          name: "线上线下营销导流</br>提升单店销售额",
        },
        {
          icon: require("../../../assets/images/app-icon22.png"),
          name: "连接优质生产商及渠道</br>优化药店产品结构",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.app-s-container {
  width: 100%;
  background: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.app-s-title {
  display: block;
  width: 7rem;
  margin-top: 2.4rem;
}
.app-s-d1 {
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: -1rem;
    li {
      float: left;
      margin-left: 1rem;
      margin-top: 1.5rem;
      .p-item {
        width: 200px;
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #f3f3f3;
        .m1 {
          width: 54px;
        }
        .s1 {
          display: block;
          font-size: 14px;
          color: #6aa5ff;
          margin-top: 0.4rem;
        }
      }
    }
  }
}
</style>